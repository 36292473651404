import styled from 'styled-components';

export const StarFill = styled.div<{ fillPercentage: number; index: number; readOnly: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  opacity: ${({ fillPercentage }) => (fillPercentage > 0 ? 1 : 0)};
  transition: ${({ readOnly }) => (readOnly ? 'none' : 'opacity 0.1s ease-in-out')};
  transition-delay: ${({ readOnly, index }) => (readOnly ? '0s' : `${index * 0.03}s`)};
`;
